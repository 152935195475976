import {
  Divider,
  Flex,
  SimpleGrid,
  Button,
  Text,
  Box,
  Tag,
  VStack,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useState } from "react";
import { Edit } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import {
  IoBedOutline,
  BsThermometerSun,
  BsThermometerSnow,
  LuBath,
  VscFlame,
  MdOutlineFireplace,
  BiBuildingHouse,
  BiSolidCarGarage,
  BiSolidCar,
  LiaRulerCombinedSolid,
  MdStraighten,
  TfiRulerAlt2,
  MdSquareFoot,
  MdOutlineDateRange,
  ImCompass2,
  MdOutlineRemoveRedEye,
  PiToilet,
  PiOven,
} from "../../assets/icons/ListingIcons";
import { userState } from "../../recoil/atoms";
import { listingFamily } from "../../recoil/listings";
import { selectedOfferAtom } from "../../recoil/offers/atoms";
import { ICON_SIZE } from "../../theme";
import {
  heatingTypeLabels,
  HeatingType,
  acTypeLabels,
  ACType,
  StoveType,
  FireplaceType,
  stoveTypeLabels,
  fireplaceTypeLabels,
  propertyTypeLabels,
  PropertyType,
  Unit,
  viewTypeLabels,
  ViewFacing,
  ListingStatus,
} from "../../types";
import { CreateOfferDrawer } from "../Offers";
import { MapboxMap } from "../common/Map/MapboxMap";
import { ListingViewingScheduleTable } from "../viewings/ListingViewingScheduleTable";
import WalkScoreDisplay from "./Walkscore";
import { formatPrice } from "../../utils/formatters";
import { IconType } from "react-icons";

const DetailSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => (
  <Box mb={8}>
    <Text fontSize="xl" fontWeight="bold" mb={3}>
      {title}
    </Text>
    {children}
  </Box>
);

const DetailItem = ({
  icon,
  label,
  value,
}: {
  icon: IconType;
  label?: string;
  value: string | number;
}) => (
  <HStack spacing={2}>
    <Icon as={icon} boxSize={ICON_SIZE} />
    <Text>
      {label && <strong>{label}:</strong>} {value}
    </Text>
  </HStack>
);

export const ListingDetails = ({ listingId }: { listingId: string }) => {
  const navigate = useNavigate();
  const listing = useRecoilValue(listingFamily(listingId));
  const user = useRecoilValue(userState);
  const isLoggedIn = !!user?.id;
  const [createOfferOpen, setCreateOfferOpen] = useState(false);
  const resetSelectedDraft = useResetRecoilState(selectedOfferAtom);

  if (!listing) return null;

  return (
    <Box
      style={
        isLoggedIn
          ? {}
          : {
              filter: "blur(7px)",
              userSelect: "none",
              pointerEvents: "none",
            }
      }
    >
      <CreateOfferDrawer
        handleClose={() => {
          setCreateOfferOpen(false);
          resetSelectedDraft();
        }}
        isOpen={createOfferOpen}
      />
      <Divider my={5} />
      <Text fontSize="2xl" fontWeight="bold" mb={6}>
        Listing Details
      </Text>

      <DetailSection title="Interior">
        <SimpleGrid columns={[1, 2]} spacing={6}>
          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Bedrooms</Text>
            <DetailItem
              icon={IoBedOutline}
              value={`Bedrooms: ${listing.bedrooms_total}`}
            />
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Heating & AC</Text>
            <DetailItem
              icon={BsThermometerSun}
              value={`Heating: ${
                heatingTypeLabels[listing.heating_type ?? HeatingType.None]
              }`}
            />
            <DetailItem
              icon={BsThermometerSnow}
              value={`AC: ${acTypeLabels[listing.ac_type ?? ACType.None]}`}
            />
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Bathrooms</Text>
            <DetailItem
              icon={LuBath}
              value={`Full Bathrooms: ${listing.bathrooms_full}`}
            />
            <DetailItem
              icon={PiToilet}
              value={`Half Bathrooms: ${listing.bathrooms_half}`}
            />
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Features</Text>
            <DetailItem
              icon={VscFlame}
              value={`Natural Gas: ${
                listing.has_natural_gas ||
                (listing.stove_type && listing.stove_type === StoveType.Gas) ||
                (listing.fireplace_type &&
                  listing.fireplace_type === FireplaceType.Gas)
                  ? "Yes"
                  : "No"
              }`}
            />
            {listing.stove_type && (
              <DetailItem
                icon={PiOven}
                value={`Stove: ${stoveTypeLabels[listing.stove_type]}`}
              />
            )}
            {listing.fireplace_type && (
              <DetailItem
                icon={MdOutlineFireplace}
                value={`Fireplace: ${
                  fireplaceTypeLabels[listing.fireplace_type]
                }`}
              />
            )}
          </VStack>
        </SimpleGrid>
      </DetailSection>

      <DetailSection title="Property">
        <SimpleGrid columns={[1, 2]} spacing={6}>
          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Building</Text>
            <DetailItem
              icon={BiBuildingHouse}
              value={`Type: ${propertyTypeLabels[listing.property_type]}`}
            />
            {listing.property_type === PropertyType.Apartment && (
              <>
                {listing.building_floor && (
                  <Text>Building Floor: {listing.building_floor}</Text>
                )}
                {listing.building_total_floors && (
                  <Text>Total Floors: {listing.building_total_floors}</Text>
                )}
              </>
            )}
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Parking Stalls</Text>
            <DetailItem
              icon={BiSolidCarGarage}
              value={`Covered: ${listing.parking_stalls_covered}`}
            />
            <DetailItem
              icon={BiSolidCar}
              value={`Uncovered: ${listing.parking_stalls_uncovered}`}
            />
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">Area</Text>
            <DetailItem
              icon={LiaRulerCombinedSolid}
              value={`Floor: ${listing.floor_area} ${
                listing.area_unit === Unit.SquareFoot ? "sqft" : "sqm"
              }`}
            />
            {listing.balcony_area && (
              <DetailItem
                icon={MdStraighten}
                value={`Balcony: ${listing.balcony_area} ${
                  listing.area_unit === Unit.SquareFoot ? "sqft" : "sqm"
                }`}
              />
            )}
            <DetailItem
              icon={TfiRulerAlt2}
              value={`Total: ${listing.total_area} ${
                listing.area_unit === Unit.Unknown ||
                listing.area_unit === Unit.SquareFoot
                  ? "sqft"
                  : "sqm"
              }`}
            />
            {listing.property_type !== PropertyType.Apartment && (
              <DetailItem
                icon={MdSquareFoot}
                value={`Lot Size: ${listing.lot_size} ${
                  listing.lot_size_unit === Unit.Unknown ||
                  listing.lot_size_unit === Unit.SquareFoot
                    ? "sqft"
                    : "sqm"
                }`}
              />
            )}
          </VStack>

          <VStack align="start" spacing={2}>
            <Text fontWeight="semibold">History</Text>
            <DetailItem
              icon={MdOutlineDateRange}
              value={`Year built: ${listing.year_built}`}
            />
            {listing.year_renovated && (
              <DetailItem
                icon={MdOutlineDateRange}
                value={`Renovated: ${listing.year_renovated}`}
              />
            )}
          </VStack>
        </SimpleGrid>
      </DetailSection>

      {(listing.view_facing || listing.view_description) && (
        <Box my={4}>
          <Text fontWeight="semibold">View</Text>
          {listing.view_facing && (
            <DetailItem
              icon={ImCompass2}
              value={`Facing: ${
                viewTypeLabels[listing.view_facing ?? ViewFacing.Unknown]
              }`}
            />
          )}
          {listing.view_description && (
            <DetailItem
              icon={MdOutlineRemoveRedEye}
              value={`Description: ${listing.view_description}`}
            />
          )}
        </Box>
      )}

      {listing.amenities &&
        (listing.amenities?.standard?.length > 0 ||
          listing.amenities.custom.length > 0) && (
          <DetailSection title="Amenities">
            <Flex flexWrap="wrap" gap={2}>
              {[...listing.amenities.standard, ...listing.amenities.custom].map(
                (amenity) => (
                  <Tag
                    key={amenity}
                    size="md"
                    p={2}
                    variant="subtle"
                    colorScheme="blue"
                    color={"white"}
                  >
                    {amenity}
                  </Tag>
                )
              )}
            </Flex>
          </DetailSection>
        )}

      <DetailSection title="Pricing">
        <VStack align="start" spacing={2}>
          <Text fontWeight="semibold">Breakdown</Text>
          <Text>
            Price:{" "}
            <Text as="span" fontWeight="semibold">
              ${formatPrice(listing.price)}
            </Text>
          </Text>
          <Text>
            Annual Property Tax:{" "}
            <Text as="span" fontWeight="semibold">
              ${formatPrice(listing.property_tax)}
            </Text>
          </Text>
          {listing.hoa_fee && (
            <Text>
              Strata Fee:{" "}
              <Text as="span" fontWeight="semibold">
                ${formatPrice(listing.hoa_fee)}
              </Text>
            </Text>
          )}
          {listing.buyer_agent_fee && (
            <Text>
              Buyer's agent fee:{" "}
              <Text as="span" fontWeight="semibold">
                ${formatPrice(listing.buyer_agent_fee)}
              </Text>
            </Text>
          )}
        </VStack>
      </DetailSection>

      <Divider my={5} />

      <DetailSection title="Location">
        <Box height="400px" width="100%">
          <MapboxMap lat={listing.latitude ?? 0} lng={listing.longitude ?? 0} />
        </Box>
        <WalkScoreDisplay listingId={listingId} />
      </DetailSection>

      <Divider my={5} />

      <DetailSection title="Schedule a Viewing">
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          {user?.token && listing.seller_id === user.id && (
            <Button
              variant="outline"
              rightIcon={<Edit size={ICON_SIZE} />}
              onClick={() => navigate(`/viewing-schedule/manage/${listingId}`)}
            >
              Edit
            </Button>
          )}
        </Flex>
        <ListingViewingScheduleTable
          listingId={listingId}
          disableButtons={!user || listing.seller_id === user.id}
        />
      </DetailSection>

      {listing.seller_id !== user?.id && (
        <Flex justifyContent="space-between" mb={10}>
          <Button
            isDisabled={listing.listing_status === ListingStatus.Sold}
            onClick={() => {
              if (user && user.token) {
                setCreateOfferOpen(true);
              } else {
                navigate("/login");
              }
            }}
            width="48%"
            size="lg"
            colorScheme="blue"
          >
            {listing.listing_status === ListingStatus.Sold
              ? "Sale Pending"
              : "Send Offer"}
          </Button>
          <Button
            onClick={() => {
              if (user && user.token) {
                navigate(`/message/${listingId}`);
              } else {
                navigate("/login");
              }
            }}
            width="48%"
            size="lg"
            variant="outline"
            colorScheme="blue"
          >
            Contact Seller
          </Button>
        </Flex>
      )}
    </Box>
  );
};
